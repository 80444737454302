import icons from '../../../../../assets/icons';
import { HeaderComponent } from './HeaderComponent';

export default function Audit() {
    return (
        <div className="flex w-full flex-col items-center justify-center gap-[40px] bg-[#07051B] py-[60px] md:gap-[60px] md:pb-[100px] min-[1200px]:gap-[80px] min-[1200px]:pb-[120px] min-[1200px]:pt-[120px]">
            <HeaderComponent title="AUDIT" subTitle="Audited By" />
            <AuditContent />
        </div>
    );
}

const AuditContent = () => {
    return (
        <div className="relative flex items-center px-[24px]">
            {/* <div className="absolute left-[-50px] md:left-[-100px] min-[1200px]:left-[-120px]">
                <img
                    src={icons.ECLIP_SHADOW}
                    alt="certik logo"
                    className="h-[200px] w-[200px] md:h-[500px] md:w-[500px]"
                />
            </div>
            <div className="absolute right-[-50px] md:right-[-100px] min-[1200px]:right-[-120px]">
                <img
                    src={icons.ECLIP_SHADOW}
                    alt="certik logo"
                    className="h-[200px] w-[200px] md:h-[500px] md:w-[500px]"
                />
            </div> */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[24px] md:gap-[60px] min-[1200px]:gap-[153px] max-w-[1200px]">
                <div className="py-2 w-full h-full flex justify-center items-center">
                    <img src={icons.homepage.audit3} alt="audit 3" className="min-[1200px]:w-[300px]" />
                </div>
                {/* <div className="h-[24px] border-[1px] border-[#24C3BC] md:h-[57px]"></div> */}
                {/* <img
                    src={icons.homepage.audit1}
                    alt="audit 1"
                    className="w-[100px] md:w-[214px] min-[1400px]:w-[300px] min-[1200px]:hidden"
                /> */}
                <div className="py-2 w-full h-full flex justify-center items-center">
                    <img
                        src={icons.homepage.audit1_desktop}
                        alt="audit 1"
                        className="min-[1200px]:w-[300px] min-[1200px]:block"
                    />
                </div>

                {/* <div className="h-[24px] border-[1px] border-[#24C3BC] md:h-[57px]"></div> */}
                {/* <img
                    src={icons.homepage.audit2}
                    alt="audit 2"
                    className="w-[100px] md:w-[214px] min-[1400px]:w-[300px] min-[1200px]:hidden"
                /> */}
                <div className="py-2 w-full h-full flex justify-center items-center col-span-1 md:col-span-2 lg:col-span-1">
                    <img
                        src={icons.homepage.audit2_desktop}
                        alt="audit 2"
                        className="min-[1200px]:w-[300px] min-[1200px]:block"
                    />
                </div>
            </div>
        </div>
    );
};
