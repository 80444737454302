import background from './background.png';
import offical_logo from './svg/starkfinance_logo.svg';

/** Social Network Icon */
import githubIcon from './svg/social/github.svg';
import telegramFooter from './svg/footer/telegram.svg';
import discordFooter from './svg/footer/discord.svg';
import twitterFooter from './svg/footer/twitter.svg';
import mediumFooter from './svg/footer/medium.svg';

/** Homepage content */
import bannerHomepageMobile from './BannerHomepage.png';
import aboutHomepageBanner from './AboutHomepage.png';
import featureCard from './svg/FeatureCard.svg';
import featureCardMobile from './svg/FeatureCardMobile.svg';
import kyc1 from './1_audit.png';
import kyc2 from './1_sale.png';
import kyc3 from './1_airdrop.png';
import kyc4 from './1_lock.png';
import audit1 from './svg/audit_1.svg';
import audit1_desktop from './svg/audit1_desktop.svg';
import audit2_desktop from './svg/audit2_desktop.svg';
import audit2 from './svg/audit_2.svg';
import audit3 from './svg/audit_3.svg';
import EXCHANGE from './svg/exchange.svg';
import MARKETPLACE from './svg/marketplace.svg';

import roadmap_md from './roadmap_md.png';
import roadmap_1440 from './roadmap_1440.png';
import roadmap_1920 from './roadmap_1440.png';
import BG_OFFICIAL from './svg/background_offical.svg';
import CERTIK_LOGO from './certik_logo.png';
import STARKGUARDIAN_LOGO from './starkguar_logo.png';
import ECLIP_SHADOW from './svg/eclipse_shadow.svg';
import ECOSYSTEM_MOBILE from './ecosystem_mobile.svg';
import ECOSYSTEM_MD from './ecosystem_md.svg';
import ECOSYSTEM_DESKTOP from './ecosystem_desktop.png';

// Roadmap
import launchpad from './svg/ecosystem/launchpad.svg';
import exchange from './svg/ecosystem/exchange.svg';
import marketplace from './svg/ecosystem/marketplace.svg';
import community from './svg/ecosystem/community.svg';
import token from './svg/ecosystem/token.png';
import nft from './svg/ecosystem/nft.svg';
import dao from './svg/ecosystem/dao.svg';

// Partners
import HASHSTACK from './partners/HASHSTACK.svg';
import GALXE from './partners/GALXE.svg';
import SITHSWAP from './partners/SITHSWAP.svg';
import TRANTOR from './partners/TRANTOR.svg';
import CARMINE from './partners/CARMINE.svg';
import LINKE from './partners/LINKE.svg';
import METABIT from './partners/METABIT.svg';
import TASKON from './partners/TASKON.svg';
import ZETACHAIN from './partners/ZETACHAIN.svg';
import OPSIDE from './partners/OPSIDE.svg';
import LAYERSWAP from './partners/LAYERSWAP.svg';
import OKX from './partners/OKX.svg';
import DMAIL from './partners/DMAIL.svg';
import DEFILAMA from './partners/DEFILAMA.svg';
import BLOCKLIT from './partners/BLOCKLIT.svg';
import KLEINLABS from './partners/KLEINLABS.svg';
import OXGEN from './partners/OXGEN.svg';
import HELA from './partners/HELA.svg';
import ZNS from './partners/ZNS.svg';
import OWL from './partners/OWL.svg';
import BASALT from './partners/BASALT.svg';
import REI from './partners/REI.svg';
import POKERDAO from './partners/POKER.svg';
import SCALEBIT from './partners/SCALE.svg';
import RESE from './partners/RESE.svg';
import CORENODE from './partners/CORENODE.svg';

const icons = {
    offical_logo,
    background,
    homepage: {
        bannerHomepageMobile,
        aboutHomepageBanner,
        featureCard,
        featureCardMobile,
        kyc1,
        kyc2,
        kyc3,
        kyc4,
        audit1,
        audit1_desktop,
        audit2,
        audit2_desktop,
        audit3,
        telegramFooter,
        discordFooter,
        twitterFooter,
        mediumFooter,
        githubIcon,
    },
    roadmap_md,
    roadmap_1440,
    roadmap_1920,
    BG_OFFICIAL,
    CERTIK_LOGO,
    STARKGUARDIAN_LOGO,
    ECLIP_SHADOW,
    ECOSYSTEM_MOBILE,
    ECOSYSTEM_MD,
    ECOSYSTEM_DESKTOP,
    EXCHANGE,
    MARKETPLACE,
    ecosystem: {
        launchpad,
        exchange,
        marketplace,
        community,
        token,
        nft,
        dao,
    },
    partners: {
        HASHSTACK,
        GALXE,
        SITHSWAP,
        TRANTOR,
        CARMINE,
        LINKE,
        METABIT,
        TASKON,
        ZETACHAIN,
        OPSIDE,
        LAYERSWAP,
        OKX,
        DMAIL,
        DEFILAMA,
        BLOCKLIT,
        KLEINLABS,
        OXGEN,
        HELA,
        ZNS,
        OWL,
        BASALT,
        REI,
        POKERDAO,
        SCALEBIT,
        RESE,
        CORENODE,
    },
};

export default icons;
