import { useEffect } from 'react';
import MainContentHomepage from './components/Content/MainContent';

const HomePage = () => {
    return (
        <div className="text-white ">
            <MainContentHomepage />
        </div>
    );
};

export default HomePage;
